
let months_array=null;
let days_array=null;
let dates_array=null;

let callback_day=null;
let callback_month=null;

function change_calendar(target,direction)
{
	let month=parseInt(target.getAttribute("current_month"));
	let year=parseInt(target.getAttribute("current_year"));
	
	if(direction=="next")
	{
		year = (month === 11) ? year + 1 : year;
		month = (month + 1) % 12;
	}else{
		year = (month === 0) ? year - 1 : year;
		month = (month === 0) ? 11 : month - 1;
	}
	callback_month(target,month,year);
	//draw_calendar(target, month, year);
}
function draw_calendar(target,month=null,year=null)
{
	const today = new Date();

	if(month==null)
	{
		month=today.getMonth();
		year=today.getFullYear();

		target.querySelectorAll(".calendar_arrow").forEach(el=>{
			el.addEventListener("click",e=>{
				e.preventDefault();
				change_calendar(target,el.getAttribute("href"));
			});
		});
	}
		
	target.setAttribute("current_month",month);
	target.setAttribute("current_year",year);

	target.querySelector(".calendar_current").innerHTML=months_array[month]+" "+year;

	const tbl = target.querySelector("tbody");
    tbl.innerHTML = "";

    //const today = new Date();
    const firstDay = new Date(year, month, 1).getDay();
    const daysInMonth = new Date(year, month + 1, 0).getDate();


    const adjustedFirstDay = (firstDay + 6) % 7;

    // creating all cells
    let date = 1;
    for (let i = 0; i < 6; i++) {
        // creates a table row
        let row = document.createElement("tr");

        //creating individual cells, filing them up with data.
        for (let j = 0; j < 7; j++) {
            const cell = document.createElement("td");
            
            if (i === 0 && j < adjustedFirstDay) {
                // Celdas vacías antes del primer día del mes
                row.appendChild(cell);
            }
            else if (date > daysInMonth) {
                // Celdas vacías después del último día del mes
                row.appendChild(cell);
            }
            else {
                const link = document.createElement("a");
                link.innerHTML = date;
                link.setAttribute("href", `${year}-${String(month+1).padStart(2,'0')}-${String(date).padStart(2,'0')}`);
                
                if (date === today.getDate() && year === today.getFullYear() && month === today.getMonth()) {
                    cell.classList.add("today");
                } 
                
                const wrap = document.createElement("div");
                wrap.classList.add("wrapper");
                wrap.appendChild(link);
                cell.appendChild(wrap);
                
                let ret = null;
                if ((ret = check_available((j + 1) % 7)) !== false) {
                    cell.classList.add("available");
                    link.setAttribute("day_shift", `${days_array[ret].day_from} - ${days_array[ret].day_to}`);
                }
                
                if (check_date(date)) {
                    cell.classList.add("dated");
                }

                row.appendChild(cell);
                date++;
            }
        }

        tbl.appendChild(row); // appending each row into calendar body.
        if (date > daysInMonth && i >= 4) {
            break;  // Stop creating rows if we've used all the days and we're past the 4th row
        }
    }



	tbl.querySelectorAll("td a").forEach(day=>{
		day.addEventListener("click",e=>{
			e.preventDefault();
			if(tbl.querySelector("td.selected"))
				tbl.querySelector("td.selected").classList.remove("selected");
			day.parentNode.parentNode.classList.add("selected");
			if(callback_day)
				callback_day(target,day.getAttribute("href"),day.getAttribute("day_shift"));
		});
	});

}

function daysInMonth(iMonth, iYear) 
{
    return 32 - new Date(iYear, iMonth, 32).getDate();
}

function check_available(day)
{
	if((days_array) && (days_array.length))
	{
		for(let d in days_array)
		{
			if(parseInt(days_array[d].day_num)==day) return(d);
		}
	}
	return(false);
}
function check_date(day)
{
	for(let d in dates_array)
	{
		if(parseInt(dates_array[d].day)==day) return(true);
	}
	return(false);
}
export function show_calendar(target,months_labels,days_available,dates,func_day_click=null,func_month_change=null)
{
	months_array=months_labels.split(",");
	days_array=days_available;
	dates_array=dates;
	callback_day=func_day_click;
	callback_month=func_month_change;
	draw_calendar(target);

	const today=new Date();
	const day=target.querySelector("[href='"+String(today.getFullYear()).padStart(2,'0')+"-"+
				String(today.getMonth()+1).padStart(2,'0')+"-"+String(today.getDate()).padStart(2,'0')+"']");
	if(day) day.click();
}

export function update_calendar(target,month,year,dates)
{
	dates_array=dates;
	draw_calendar(target,month,year);
}

export function update_calendar_days(target,days_available)
{
	days_array=days_available;
	draw_calendar(target);
}