import { server_base, page_text,page_lng,set_enter,set_version,translate_page,server_function,old_server_function } from './farmaval.js';
import {show_calendar,update_calendar,update_calendar_days} from "./calendar.js";

let pharm_training=null;

function test_pending(state)
{
	//return(false);
	if(state!=3 && state!=4) return(false);

	const prev=document.querySelector("section.selected");
	if(prev) prev.classList.remove("selected");
	if(state==3)
		document.getElementById("section_pending").classList.add("selected");
	else 
		document.getElementById("section_checking").classList.add("selected");
	
	document.getElementById("btn_logout").classList.remove("hide");
	document.getElementById("main").classList.remove("hide");
	return(true);
}

function show_logged(logged,passwords=false)
{
	let show=".nologged";
	let hide=".logged";
	if(logged)
	{
		show=".logged";
		hide=".nologged";
		document.querySelector("body").classList.add("user_logged");
	}else{
		document.querySelector("body").classList.remove("user_logged");
	}
	document.querySelectorAll(show).forEach(el=>
	{
		el.classList.remove("hide");
	});
	document.querySelectorAll(hide).forEach(el=>
	{
		el.classList.add("hide");
	});
	
	document.getElementById("login_user").value="";
	document.getElementById("login_password").value="";
	document.getElementById("new_password").value="";
	document.getElementById("renew_password").value="";
	if(passwords)
	{
		document.getElementById("section_login").classList.add("hide");
		document.getElementById("section_update_password").classList.remove("hide");
	}else{
		//document.getElementById("section_login").classList.remove("hide");
		document.getElementById("section_update_password").classList.add("hide");
	}
}

function load()
{
	set_version();
	translate_page(page_lng);
	set_enter();

	document.querySelectorAll(".btn_lng").forEach(el=>{
		el.addEventListener("click",e=>{
			e.preventDefault();
			translate_page(el.getAttribute("href"),true);
		});
	});


	if(document.getElementById("btn_login"))
	{
		document.getElementById("btn_login").addEventListener("click",e=>{
			e.preventDefault();

			const login_error=document.querySelector(".login_error");
			login_error.classList.add("hide");

			try{
				server_function("login",{user_name:document.getElementById("login_user").value,
									 user_password:document.getElementById("login_password").value,
									 lng:page_lng},data=>
				{
					console.log("Login:",data);
					if(data.status)
					{
						localStorage.farmaval_session=data.session;
						
						if(data.pharm.pharm_reset_password==1)
							show_logged(false,true);
						else{
							load_pharm_data(data);
							if(!test_pending(data.pharm.pharm_active))
								show_logged(true);
						}
					}else{
						login_error.innerHTML=page_text["login_error_user"];
						login_error.classList.remove("hide");
					}
				},false);
			}catch(err){
				login_error.innerHTML=page_text["login_error_server"];
				login_error.classList.remove("hide");
			}

		});
	}

	server_function("getPharm",{lng:page_lng},data=>
	{
		console.log("getPharm:",data);
		if(data.status)
		{
			if(data.admin_mode) 
			{
				document.getElementById("check_turn_mode").checked=true;
				document.body.setAttribute("mode-owner",true);
			}
			if(data.pharm.pharm_reset_password==1)
				show_logged(false,true);
			else{
				if(!test_pending(data.pharm.pharm_active))
					show_logged(true);
				load_pharm_data(data);
			}
		}else{
			show_logged(false);
		}			
		
	});

	const turn_mode=document.getElementById("check_turn_mode");
	if(turn_mode)
	{
		turn_mode.addEventListener("click",async e=>{

			e.preventDefault();
			const original=e.target.checked;
			const params={};
			if(original)
			{
				const prompt=await alert_popup(page_text["alert_code_title"],page_text["alert_code_info"],false,true,true,
					{ link:page_text["alert_code_recover"],info:page_text["alert_code_recover_info"],
						callback:async function(){

							const res=await server_function("recoverAdminPassword",{lng:page_lng});
							//console.log(res);
							if((!res) || (!res.status))
							{
								alert_popup(page_text["alert_code_title"],page_text["alert_code_recover_error"],true);
								return;
							}
							alert_popup(page_text["alert_code_title"],page_text["alert_code_recover_email"]+"<b>"+res["email"]+"</b>");
						}
					}
				);
				if(!prompt) return;
				params.code=prompt;
			}
			const res=await server_function("turnMode",params);
			console.log(res);
			if((!res) || (!res.status))
			{
				alert_popup(page_text["alert_code_title"],page_text["alert_code_wrong"],true);
				return;
			}
			show_training(res.training);

			e.target.checked=res.mode; //original;
			document.body.setAttribute("mode-owner",e.target.checked);
			if(!e.target.checked)
			{
				if(document.querySelector(`[href="profile_admin"].selected`))
					document.querySelector(`[href="profile_data"]`).click();
			}
		});
	}

	if(document.getElementById("btn_logout"))
	{
		document.getElementById("btn_logout").addEventListener("click",e=>
		{
			e.preventDefault();
			server_function("logout",{},data=>{
				//show_logged(false);
				location.reload();
				console.log("Logout:",data);
			});
		});
	}

	document.querySelectorAll("[aria-btn-trigger]").forEach(element=>{
		element.addEventListener('keypress', function(e)
		{
			if(e.key==="Enter")
			{
				document.getElementById(this.getAttribute("aria-btn-trigger")).dispatchEvent(new Event('click'));
			}
		},false);
	});

	document.querySelectorAll(".btn_signature").forEach(el=>{
		el.addEventListener("click",e=>{
			e.preventDefault();
			const popup=document.getElementById(el.getAttribute("href"));
			popup.setAttribute("target",el.getAttribute("target"));
			popup.classList.remove("hide");

			const canvas=popup.querySelector("canvas");
			let ctx=canvas.getContext("2d");
			ctx.canvas.width = canvas.offsetWidth;
			ctx.canvas.height = canvas.offsetHeight;
		});
	});
	
	document.querySelectorAll(".popup_signature").forEach(el=>{
		
		const canvas=el.querySelector("canvas");
		enable_touchevents(canvas);
		let mousePressed = false;
		let x=0, y=0;
		let ctx=canvas.getContext("2d");

		canvas.addEventListener("mousedown",e=>{
			mousePressed = true;
			x=e.offsetX; //e.clientX - canvas.offsetLeft;
			y=e.offsetY; //e.clientY - canvas.offsetTop;
		});
		canvas.addEventListener("mouseup",e=>{
			mousePressed = false;
		});
		canvas.addEventListener("mousemove",e=>{
			if(!mousePressed) return;

			ctx.beginPath();
			ctx.strokeStyle="black";
			ctx.lineWidth=1;
			ctx.lineJoin="round";
			ctx.moveTo(x, y);
			x=e.offsetX; //e.clientX - canvas.offsetLeft;
			y=e.offsetY; //e.clientY - canvas.offsetTop;
			ctx.lineTo(x, y);
			ctx.stroke();
		});
		canvas.addEventListener("mouseleave",e=>{
			mousePressed = false;
		});

		el.querySelector(".btn_signature_cancel").addEventListener("click",e=>{
			e.preventDefault();
			ctx.setTransform(1, 0, 0, 1, 0, 0);
    		ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
			el.classList.add("hide");
		});
		el.querySelector(".btn_signature_use").addEventListener("click",e=>{
			e.preventDefault();
			document.getElementById(el.getAttribute("target")).src = canvas.toDataURL();
			
			ctx.setTransform(1, 0, 0, 1, 0, 0);
    		ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
			el.classList.add("hide");
		});
		el.querySelector(".btn_signature_clear").addEventListener("click",e=>{
			e.preventDefault();
			ctx.setTransform(1, 0, 0, 1, 0, 0);
    		ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
		});
	});
	

	document.querySelectorAll(".btn_select_file").forEach(el=>{
		const input=document.getElementById(el.getAttribute("href"));
		el.addEventListener("click",e=>{
			e.preventDefault();
			input.click();
		});
		
		input.addEventListener("change",function(e)
		{
			e.preventDefault();
			if(this.files.length === 0) return;
			var imageFile = this.files[0];
			
			const target=document.getElementById(input.getAttribute("target"));
		
			var reader = new FileReader();
			reader.onload = (function(aImg){
				 return function(e) 
				 { 
					target.setAttribute("src",e.target.result);
					//document.getElementById("nft_thumb_image").classList.remove("hide");
					//el.querySelector("label").classList.add("hide");
				}; 
			})(target);
			reader.readAsDataURL(imageFile);
		});
	})

	document.querySelectorAll("[aria-section]").forEach(el=>{
		el.addEventListener("click",e=>{
				e.preventDefault();
				el.parentNode.querySelector(".selected").classList.remove("selected");
				el.classList.add("selected");
				document.querySelector("section.selected").classList.remove("selected");
				document.getElementById(el.getAttribute("aria-section")).classList.add("selected");
		});
	});

	document.getElementById('new_password').addEventListener("input",validate_password);
	document.getElementById('renew_password').addEventListener("input",validate_password);

	document.getElementById("btn_update_password").addEventListener("click",e=>{
		e.preventDefault();	
		if(e.target.disabled) return;
		const update_error=document.querySelector("#section_update_password .login_error");		
		update_error.classList.add("hide");
		server_function("updatePassword",{
			password:document.getElementById('new_password').value},res=>
		{
			if(res.status)
			{
				location.reload();
			}else{
				update_error.classList.remove("hide");
			}
		});
	});

	/*
	document.querySelectorAll(".video_link").forEach(el=>{
		el.addEventListener("click",e=>{
				e.preventDefault();
				if(el.parentNode.querySelector(".selected"))
					el.parentNode.querySelector(".selected").classList.remove("selected");
				el.classList.add("selected");
				document.getElementById("formation_video").setAttribute("src",el.getAttribute("href"));
		});
	});

	const all_videos_sati=parseInt(document.querySelectorAll("#formation_videos_sati .video_link").length);
	document.getElementById("videos_sati_all").innerHTML=all_videos_sati;
	document.getElementById("videos_sati_team").innerHTML=all_videos_sati-parseInt(document.querySelectorAll("#formation_videos_sati  .video_link[noteam]").length);
	
	document.querySelectorAll(".formation_type").forEach(el=>{
		el.addEventListener("click",e=>{
				e.preventDefault();
				if(el.parentNode.querySelector(".selected"))
					el.parentNode.querySelector(".selected").classList.remove("selected");
				el.classList.add("selected");
				const parent=document.getElementById(el.getAttribute("href"));
				const type=el.getAttribute("aria-type");
				parent.querySelectorAll(".video_link[noteam]").forEach(video=>{
					if(!type)
						video.classList.remove("hide");
					else
						video.classList.add("hide");	
				});

		});
	});
	*/

	document.querySelectorAll(".tab_btn").forEach(el=>{
		el.addEventListener("click",e=>{
				e.preventDefault();
				if(el.classList.contains("selected")) return;
				
				const prev=el.parentNode.querySelector(".tab_btn.selected");
				if(prev)
				{
					prev.classList.remove("selected");
					document.getElementById(prev.getAttribute("href")).classList.add("hide");
				}
				el.classList.add("selected");

				if(el.hasAttribute("aria-function"))
					custom_functions[el.getAttribute("aria-function")](el)

				document.getElementById(el.getAttribute("href")).classList.remove("hide");
		});
	});

	document.querySelectorAll(".operation_confirm input").forEach(el=>{
		el.addEventListener("keydown",e=>{
			if (e.keyCode == 27) 
			{
				el.value="";
				el.parentElement.classList.add("hide");
			}
		});
	});

	document.querySelectorAll(".close_popup").forEach(el=>{
		el.addEventListener("click",e=>{
			e.preventDefault();
			document.getElementById(el.getAttribute("href")).classList.remove("visible");
		});
	});

	document.querySelectorAll(".popup_cover").forEach(el=>{
		el.addEventListener("click",e=>{
			e.preventDefault();
			const w=document.querySelector(".popup.visible");
			if(w) w.classList.remove("visible");
		});
	});

	document.querySelectorAll(".service_item").forEach(el=>{
		el.addEventListener("click",e=>{
			if(el.classList.contains("disabled"))
			{
				e.preventDefault();
				return;
			}
			if(el.hasAttribute("default"))
				return;
			
			e.preventDefault();
			
			const service_url= location.hostname === "localhost" 
				? "http://localhost/farmaval/service.php"
				: "https://www.farmaval.net/app/service.php";

			const iframe=document.getElementById("service_iframe");
			iframe.src=service_url+"?id="+el.getAttribute("href")+"&session="+localStorage.farmaval_session+ 
							"&type=v2";
			iframe.classList.remove("hide");
		});
	});
	// message from service
	window.addEventListener('message', function (e) {
		console.log("Post Message:",e);
		if(e.data.msg=="close_service")
		{
			document.getElementById("service_iframe").classList.add("hide");
			update_cache(null);	
			if(e.data.login)
				show_logged(false);
				//location.reload();
		}else{ 
			if(e.data.msg=="service_error")
			{
				console.log("service_error:",e.data);
				let services=null;
				if(this.localStorage.backup_services)
					services=JSON.parse(this.localStorage.backup_services);
				else 
					services=[];
				services.push(e.data.service);
				localStorage.backup_services=JSON.stringify(services);
			}
		}	
	});

	document.querySelectorAll("[aria-popup]").forEach(el=>{
		el.addEventListener("click",e=>{
			e.preventDefault();

			const popup=document.getElementById(el.getAttribute("href"));
			if(popup)
			{
				empty_fields(popup);
				const callback=el.getAttribute("aria-prefunction");
				if(callback)
					myfuncs[callback](popup);

				clear_errors(popup,"add");
				if(popup.classList.contains("popup"))
					popup.classList.add("visible");
			}
		});
	});

	document.querySelectorAll("[aria-today]").forEach(el=>{
		const today = new Date();
		el.value = today.getFullYear() + '-' + ('0' + (today.getMonth() + 1)).slice(-2) + '-' + ('0' + today.getDate()).slice(-2);
	});

	document.querySelectorAll(".btn_show_report").forEach(el=>{
		el.addEventListener("click",e=>
		{
			e.preventDefault();
			const viewer=document.getElementById("report_view");
			viewer.querySelector("iframe").setAttribute("src",el.getAttribute("href"));
			viewer.classList.remove("hide");	
		});
	});
	// message from report
	window.addEventListener('message', async function (e) {
		console.log("Post: ",e);

		if(e.data==null) return;

		if(typeof e.data === 'object') 
		{
			if(e.data.msg=="farmaval_signature")
			{
				document.getElementById(e.data.type).setAttribute("src",e.data.img);
			}
			return;
		}

		if(e.data=="farmaval_close_agreement")
		{
			document.getElementById("agreement_popup").classList.add("hide");
			
			if(document.getElementById("ag_signer_signature").hasAttribute("src") 
				&& document.getElementById("ag_debtor_signature").hasAttribute("src"))
			{
				document.getElementById("btn_pending_upload").classList.remove("hide");
				document.getElementById("btn_pending_again").classList.remove("hide");
				document.getElementById("btn_pending_service").classList.add("hide");

				document.getElementById("pending_data").querySelectorAll("[aria-field]").forEach(el=>
					{
						el.disabled=true;
					});
			}
			
			return;
		}
		if(e.data=="farmaval_agreement_data")
		{
			const form="pending_data"; //document.getElementById("agreement_popup").getAttribute("aria-form");
			
			const now=new Date();

			const data={
				ag_date_day:now.getDate(),
				ag_date_month_es:page_text_es["pending_months"].split(",")[now.getMonth()],
				ag_date_month_ca:page_text_ca["pending_months"].split(",")[now.getMonth()],
				ag_date_year:now.getFullYear(),

				ag_date:new Date().toLocaleDateString('es-ES', {
				year: 'numeric',
				month: 'long',
				day: 'numeric'
			  })};
			document.getElementById(form).querySelectorAll("[aria-field]").forEach(el=>{
				//console.log(el.value);
				if(el.value && el.value!=="")
				{
					if(el.tagName==="SELECT")
						data[el.getAttribute("id")]=el.options[el.selectedIndex].text
					else
						data[el.getAttribute("id")]=el.value;
				}
			});

			e.source.postMessage({msg:"farmaval_agreement_data",data:data});

			return;
		}

		if(e.data=="close_report")
			document.getElementById("report_view").classList.add("hide");	
		if(e.data=="farmaval_close_report")
			document.getElementById("report_view").classList.add("hide");	
		if(e.data=="farmaval_send_data")
		{
			// get repor for
			const popup=document.getElementById("popup_report");
			server_function("getDataForReport",{
				rep_id:popup.querySelector("#rep_id").value,
				rep_service:popup.querySelector("#rep_service").value,
				server_base:server_base
				},res=>
			{
				//console.log("Result: ",res);
				if(res.status)
				{
					e.source.postMessage({msg:"farmaval_data",conditions:res.conditions,values:res.values});
				}else{
					document.getElementById("report_view").classList.add("hide");
					alert ("error");
				}
			});
			
		}	
	});

	if(document.getElementById("btn_print_stats"))
	{
		document.getElementById("btn_print_stats").addEventListener("click",e=>{
			e.preventDefault();
			
            const w = window.open('stats.html', '');
			w.onload = function() {
				const el=w.document.querySelector('.print_preview');
				el.innerHTML=document.getElementById("report_stats_window").innerHTML;

				el.querySelectorAll("[aria-data]").forEach(lbl=>{
					const date = new Date(document.getElementById(lbl.getAttribute("aria-data")).value); 
					lbl.innerHTML=date.toLocaleString('es',{ year: 'numeric', month: '2-digit', day: '2-digit' });
				});
				el.querySelectorAll("canvas").forEach(canvas=>{
					const from=document.getElementById(canvas.getAttribute("id"));
					if(from)
						canvas.getContext("2d").drawImage(from,0,0);

				});
				setTimeout(function()
				{
					w.print();
				},1000);
			};
		});
	}
	if(document.getElementById("btn_print_stats_iretia"))
	{
		document.getElementById("btn_print_stats_iretia").addEventListener("click",e=>{
			e.preventDefault();
			
            const w = window.open('stats.html', '');
			w.onload = function() {
				const el=w.document.querySelector('.print_preview');
				el.innerHTML=document.getElementById("report_stats_iretia_window").innerHTML;
				/*
				el.querySelectorAll("[aria-data]").forEach(lbl=>{
					const date = new Date(document.getElementById(lbl.getAttribute("aria-data")).value); 
					lbl.innerHTML=date.toLocaleString('es',{ year: 'numeric', month: '2-digit', day: '2-digit' });
				});
				*/
				setTimeout(function()
				{
					w.print();
				},1000);
			};
		});
	}
	document.querySelectorAll("[aria-function]").forEach(el=>{
		el.addEventListener("click",e=>{
			e.preventDefault();

			let params={lng:page_lng};
			const parent=document.getElementById(el.getAttribute("href"));
			if(parent)
			{
				show_result(parent.getAttribute("aria-error-element"));

				const noreq=el.hasAttribute("noreq");

				const fields=parent.querySelectorAll("[aria-field]");
				
				for(const field of fields)
				{
					let valid=true;
					if(field.tagName=="IMG")
					{
						if(field.hasAttribute("required"))
							valid=field.getAttribute("src") ? true : false;
					}else{
						valid=field.checkValidity();
					}	
					if((!valid) && (!noreq))
					{
							field.classList.add("novalid");
					}else{
						field.classList.remove("novalid");
						if(field.tagName=="IMG")
						{
							if(field.hasAttribute("src"))
								params[field.getAttribute("id")]=field.getAttribute("src");
						}else{
							params[field.getAttribute("id")]=field.value;
						}
					}
				
				}
				if(parent.querySelector("[aria-field].novalid"))
				{
					show_result(parent.getAttribute("aria-error-element"),"error_empty_fields",true);
					return;
				}
			}
			if(el.hasAttribute("aria-custom-function"))
			{
				custom_functions[el.getAttribute("aria-function")](el);
				return;
			}

			if(el.getAttribute("aria-confirm"))
			{
				const confirm=document.getElementById(el.getAttribute("aria-confirm"));
				const input=confirm.querySelector("input");
				if(input.value!=page_text[input.getAttribute("aria-text")])
				{
					confirm.classList.remove("hide");
					input.focus();
					return;
				}
			}
			if(el.getAttribute("aria-pre-function"))
				myfuncs[el.getAttribute("aria-pre-function")](parent,params);

			console.log("Params "+el.getAttribute("aria-function")+": ",params);
			server_function(el.getAttribute("aria-function"),params,res=>
			{
				console.log("Result: ",res);
				if(res.status)
				{
					if(!el.hasAttribute("aria-sameform"))
					{
						if(parent.classList.contains("popup"))
							parent.classList.remove("visible");
						clear_errors(parent,"add");
						empty_fields(parent);
					}

					if(!el.hasAttribute("aria-nook"))
						show_result(parent.getAttribute("aria-error-element"),"result_ok");

					if(el.getAttribute("aria-post-function"))
						myfuncs[el.getAttribute("aria-post-function")](res);

					if(el.getAttribute("aria-table"))
						fill_table(document.getElementById(el.getAttribute("aria-table")),res.data,item_click);	

					if(el.hasAttribute("aria-list"))
					{
						if(res.data)
						{
							const list=document.getElementById(el.getAttribute("aria-list"));
							fill_list(list,res.data[list.getAttribute("aria-load")],item_click);
						}
					}
					
				}else{
					show_result(parent.getAttribute("aria-error-element"),"error_operation",true,res.error);
				}
			});
		});
	});

	if(document.getElementById("btn_syncro_now"))
	{
		document.getElementById("btn_syncro_now").addEventListener("click",e=>{
			e.preventDefault();
			const services=JSON.parse(localStorage.backup_services);
			const list=document.querySelectorAll("#table_cache_services tbody .backup_row");
			syncro_all_services(services,list);
		});
	}
}

async function syncro_service(params)
{
	//params.new_server=true;
	//params.data.type=null;

	let res;
	if(params.new_server)
		res= await server_function(null,params);
	else
		res= await old_server_function(params);

	//console.log(res);

	if((res.result=="ok") || (res.status))
		return({res:true});
	else
		return({res:false,error:"Error: "+res.type});
}

async function syncro_all_services(services,list)
{
	let bad_services=[];
	let count=list.length;
	let row=services.length-1;

	for(let e=0;e<list.length;e++)
	{
		const element=list[e];
		console.log("Syncro:",services[row]);
		const ret=await syncro_service(services[row]);

		if(ret.res)
		{
			element.classList.add("done");
			element.classList.remove("error");
			element.querySelector(".result").innerHTML="OK";
			count--;	
			
			setTimeout(function(){
				const parent=element.parentNode;
				element.remove();
				if(!parent.hasChildNodes())
					document.getElementById("syncro_services_wrapper").classList.add("disabled");
			},1000);
			
		}else{
			bad_services.push(services[row]);
			element.classList.add("error");
			element.classList.remove("done");
			element.querySelector(".result").innerHTML=ret.error;
		}
		row--;

		if(row<0)
		{
			localStorage.backup_services=JSON.stringify(bad_services);
			document.querySelectorAll(".syncro_data_services").forEach(el=>{
				el.innerHTML=count;
			});
			//if(!count)
			//	document.getElementById("syncro_services_wrapper").classList.add("hide");
		}	
	};
}

function enable_touchevents(canvas)
{
	canvas.addEventListener("touchstart", function (e) {
		var touch = e.touches[0];
		var mouseEvent = new MouseEvent("mousedown", {
			clientX: touch.clientX,
			clientY: touch.clientY
		});
		//alert(mouseEvent.clientX);
		canvas.dispatchEvent(mouseEvent);
	}, false);
	canvas.addEventListener("touchend", function (e) {
		var mouseEvent = new MouseEvent("mouseup", {});
		canvas.dispatchEvent(mouseEvent);
	}, false);
	canvas.addEventListener("touchmove", function (e) {
		var touch = e.touches[0];
		var mouseEvent = new MouseEvent("mousemove", {
			clientX: touch.clientX,
			clientY: touch.clientY
		});
		canvas.dispatchEvent(mouseEvent);
	}, false);	
	
	document.body.addEventListener("touchstart", function (e) {
    if (e.target == canvas) {
        e.preventDefault();
    }
	}, { passive: false });
	document.body.addEventListener("touchend", function (e) {
    if (e.target == canvas) {
        e.preventDefault();
    }
	}, { passive: false });
	document.body.addEventListener("touchmove", function (e) {
    if (e.target == canvas) {
        e.preventDefault();
    }
	}, { passive: false });

}

function show_result(el,text=null,error=false,type=null)
{
	const err=document.getElementById(el);
	
	if(err)
	{
		if(text)
		{
			if(error)
				err.classList.remove("server_ok");
			else
				err.classList.add("server_ok");

			err.innerHTML=page_text[text];
			if(type)
			{
				if(page_text[type])
					type=page_text[type]
				err.innerHTML+=" ("+type+")";
			}
			err.classList.remove("hide");
		}else 
			err.classList.add("hide");
	}
}

function fill_pharm_options(data,type,field_name,field_value,more_values=null)
{
	if(data[type])
	{
		document.querySelectorAll("[aria-pharm-data='"+type+"']").forEach(el=>{
			el.innerHTML="";
			for(const s in data[type])
			{
				const option = new Option(data[type][s][field_name],data[type][s][field_value]);
				if(more_values)
				{
					for(const v in more_values)
						option.setAttribute(more_values[v].name,data[type][s][more_values[v].field]);
				}
				el.add(option);
			}
		});
	}
}

function update_cache(data)
{
	if(data)
	{
		document.querySelectorAll("[aria-cache]").forEach(el=>{
			const type=el.getAttribute("aria-cache");
			if(data[type])
			{
				el.innerHTML=data[type].length;
				localStorage[type]=JSON.stringify(data[type]);
			}
		});
	}
	const count=localStorage.backup_services ? JSON.parse(localStorage.backup_services).length : 0;
	document.querySelectorAll(".syncro_data_services").forEach(el=>{
		el.innerHTML=count;
	});

	if(!count)
	{
		document.getElementById("syncro_services_wrapper").classList.add("disabled");
	}else{
		document.getElementById("syncro_services_wrapper").classList.remove("disabled");
		if(localStorage.backup_services)
		{
			const services=JSON.parse(localStorage.backup_services);
			const table=document.querySelector("#table_cache_services tbody");
			table.innerHTML="";
			for(let a=count-1;a>=0;a--)
			{
				console.log("Cache: ",services[a]);
				const item=document.querySelector("#elements .backup_row").cloneNode(true);
				
				item.querySelector("[aria-field='service_date']").innerHTML=services[a].rep_start||services[a].data.start;
				item.querySelector("[aria-field='service_type']").innerHTML=services[a].rep_service||services[a].data.type;				
				table.append(item);
			}
		}
	}	

	if(localStorage.syncro_date)
		document.getElementById("syncro_data_date").innerHTML=localStorage.syncro_date;
	else 
		document.getElementById("syncro_data_date").innerHTML="---";

}

function load_pharm_data(data,month=null,year=null)
{
	
	//console.log("Data: ",data);

	document.getElementById("ag_pharm").value=data.pharm.pharm_id;
	if(data.agreement)
		fill_form("pending_data",data.agreement);

	const now= new Date();
	localStorage.syncro_date=now.toLocaleDateString()+" "+now.toLocaleTimeString();;
	update_cache(data.data);
	
	// services
	for(let service in data.data.services)
		document.getElementById("service_"+data.data.services[service].ps_service).classList.remove("disabled");
	
	fill_pharm_options(data.data,"staff","staff_name","staff_id");
	fill_pharm_options(data.data,"services","ps_service","ps_service");
	fill_pharm_options(data.data,"lngs","text","id");

	fill_pharm_options(data.data,"products","prod_name","prod_id",[{name:"aria-cost",field:"prod_cost"}]);
	const select=document.getElementById("report_product_type");
	select.addEventListener("change",el=>
	{
		document.getElementById("report_product_id").value=select.options[select.selectedIndex].value;
		document.getElementById("report_product_cost").value=select.options[select.selectedIndex].getAttribute("aria-cost");
		document.getElementById("report_product_quantity").value="1";
	});
	
	select.dispatchEvent(new Event('change'));

	document.getElementById("btn_product_add").addEventListener("click",e=>{
		e.preventDefault();
		const prod={
					sale_id:"0",
					sale_product_id:document.getElementById("report_product_type").value,
					sale_product_name:select.options[select.selectedIndex].innerHTML,
					sale_cost:document.getElementById("report_product_cost").value,
					sale_quantity:document.getElementById("report_product_quantity").value
				};

		if(((prod.sale_product_name!="") && (prod.sale_cost!="")) && (prod.sale_quantity!=""))
			add_to_list(document.getElementById("table_sales"),prod,sale_click,"aria-col");
		
	});


	fill_form("form_pharm",data.pharm);
	fill_form("form_admin",data.pharm);

	if(data.pharm.pharm_current_password)
		document.getElementById("pharm_password").removeAttribute("required");
	else
		document.getElementById("pharm_password").removeAttribute("placeholder");
	
	if(data.pharm.pharm_current_admin_password)
		document.getElementById("pharm_admin_password").removeAttribute("required");
	else
		document.getElementById("pharm_admin_password").removeAttribute("placeholder");
	
	
	document.querySelectorAll("[aria-load]").forEach(el=>{
		const type=el.getAttribute("aria-load");
		console.log(data.data[type]);

		fill_list(el,data.data[type],item_click);

	});

	if(month==null)
	{
		const today = new Date();
		month=today.getMonth()+1;
		year=today.getFullYear();
	}

	server_function("getMonthDates",{month:month,year:year},res=>
	{
		if(res.dates)
		{
			document.querySelectorAll(".calendar").forEach(el=>
			{
					show_calendar(el,page_text["calendar_months"],data.data.days,res.dates,
						calendar_day_click, calendar_month_change);
			});
		}			
		//console.log("getMonthDates:",res);
	});

	// Training 
	if(!data.data.training) return;
	
	show_training(data.data.training);

}

function show_training(data)
{
	//console.log("Training:",data);
	pharm_training=data;
	training_show_sections(data);

}
function training_show_sections(tr_data,section=0)
{
	const training=document.getElementById("section_videos");
	const folders=document.getElementById("training_content");
	const videos=document.getElementById("training_videos");
	
	training.querySelector(".formation_video").classList.add("hide");

	const data=tr_data.sections;
	if(data[section])
	{
		videos.classList.add("hide");
		const tab_sections=training.querySelector(".tabs_top");
		
		if(section==0)
		{
			tab_sections.innerHTML="";
			const item=document.createElement('a');
			item.classList.add("tab_btn");
			item.href="Inicio";
			item.innerHTML="Inicio";
			item.addEventListener("click",e=>{
				e.preventDefault();
				training_show_sections(tr_data,0);
			});
			tab_sections.append(item);
		}
		const training_content=document.getElementById("training_content");
		training_content.innerHTML="";

		for(let a in data[section])
		{
			const t=document.querySelector("#elements .training_item").cloneNode(true);
			if(data[section][a].ts_cover) t.querySelector("img").src=data[section][a].ts_cover;
			if(data[section][a].ts_author) t.querySelector(".author").innerHTML=data[section][a].ts_author;
			t.querySelector(".title").innerHTML=data[section][a].ts_name;

			t.href=data[section][a].ts_name;
			t.setAttribute("aria-section-id",data[section][a].ts_id);

			t.addEventListener("click",e=>
				{
					e.preventDefault();
					training_show_sections(tr_data,data[section][a].ts_id, data[section][a].ts_name);
					
					const lbl=document.createElement('a');
					lbl.classList.add("tab_btn");
					lbl.href=data[section][a].ts_name;
					lbl.innerHTML=data[section][a].ts_name;
					lbl.addEventListener("click",e=>
					{
						e.preventDefault();	
						training_show_sections(tr_data,data[section][a].ts_id);
						// eliminar siguientes
						while (e.target.nextSibling) {
							e.target.nextSibling.remove();
						}
					}); 
					tab_sections.append(lbl);
				});

			training_content.append(t);
		}
		folders.classList.remove("hide");
	}else{

		folders.classList.add("hide");
		if(tr_data.content[section])
		{
			if(tr_data.content[section].videos)
			{
				training.querySelector(".formation_label.label_videos").classList.remove("hide");
				training_add_videos(training,tr_data.content[section].videos);
			}else{
				training_add_videos(training,null);
				//training.querySelector(".formation_label.label_videos").classList.add("hide");
				//training.querySelector(".videos_list").innerHTML="";
			}
			if(tr_data.content[section].docs)
			{
				training.querySelector(".formation_label.label_material").classList.remove("hide");
				training_add_docs(training,tr_data.content[section].docs);
			}else{
				training_add_docs(training,null);
				//training.querySelector(".formation_label.label_material").classList.add("hide");
				//training.querySelector(".material_list").innerHTML="";
			}
		}else{
			training.querySelector(".formation_label.label_videos").classList.add("hide");
			training.querySelector(".videos_list").innerHTML="";
			training.querySelector(".formation_label.label_material").classList.add("hide");
			training.querySelector(".material_list").innerHTML="";
		}
		videos.classList.remove("hide");

	}
}

function training_add_videos(parent,content)
{
	
	if(content.length<10)
	{
		const target=parent.querySelector(".training_grid");

		parent.querySelector(".training_list").classList.add("hide");
		target.classList.remove("hide");	
		
		const grid=target.querySelector(".videos_list");
		grid.innerHTML="";		

		if(!content) return;
		if(!content.length) return;

		let order=1;
		for(let v of content)
		{
			const item=document.querySelector("#elements .item_video").cloneNode(true);
			item.querySelector("iframe").src=v.training_url;
			item.querySelector(".item_video_title").innerHTML=order+". "+v.training_title;
			grid.append(item);
			order++;
		}

		return;
	}

	const target=parent.querySelector(".training_list");
	target.classList.remove("hide");
	parent.querySelector(".training_grid").classList.add("hide");
	const grid=target.querySelector(".videos_list");
	grid.innerHTML="";
	
	if(!content) return;
	if(!content.length) return;
		
	const video_iframe=parent.querySelector(".formation_video");
	for(let v of content)
	{
		const item=document.createElement('a');
		item.classList.add("video_link");
		item.href=v.training_url;
		item.innerHTML=v.training_title;

		item.addEventListener("click",e=>{
			e.preventDefault();
			if(item.parentNode.querySelector(".selected"))
				item.parentNode.querySelector(".selected").classList.remove("selected");
			item.classList.add("selected");
			video_iframe.classList.remove("hide");
			video_iframe.setAttribute("src",item.getAttribute("href"));
		});
		grid.append(item);
	}
}
function training_add_docs(parent,content)
{
	const target=parent.querySelector(".training_style:not(.hide)");

	const grid=target.querySelector(".material_list");
	grid.innerHTML="";

	if(!content) return;
	if(content.length==0) return;

	const icons=document.querySelector("#elements .filetypes");
	for(let v of content)
	{
		const item=document.createElement('a');
		item.classList.add("material_link");
		item.href=v.training_url;
		item.target="_new";
		const ext=v.training_url.split(".").slice(-1)[0];
		let icon=icons.querySelector(`[aria-extension-${ext}]`);
		if(!icon) icon=icons.querySelector("[aria-extension-generic]");
		//item.append(v.training_title+" <b><small>("+ext+")</small></b>");
		item.append(icon.cloneNode(true));
		item.append(v.training_title);
		grid.append(item);
	}
}

function remove_sale(e)
{
	e.preventDefault();
	const item=e.target.parentElement;
	if(item.querySelector("[aria-col='sale_id']").innerHTML=="0")
		item.remove();
	else
		item.classList.add("hide");
//	alert("remove");
}
function sale_click(parent,item)
{
	if(item.classList.contains("selected"))
	{
		item.classList.remove("selected");
		return;
	}
	const prev=parent.querySelector(".row_select.selected")
	if(prev)
		prev.classList.remove("selected");
	item.classList.add("selected");

	item.querySelector(".col_btn").removeEventListener("click",remove_sale);
	item.querySelector(".col_btn").addEventListener("click",remove_sale);
}
function calendar_month_change(target,month, year)
{
	server_function("getMonthDates",{month:month+1,year:year},res=>
	{
		update_calendar(target,month,year,res.dates);
	});
}
function calendar_day_click(target,day,shift)
{
	const date=day.split("-");
	document.getElementById("calendar_date").innerHTML=date[2]+"/"+date[1]+"/"+date[0];
	if(shift){
		document.getElementById("calendar_shift").innerHTML=shift;
	}else{
		document.getElementById("calendar_shift").innerHTML=page_text["date_no_shift"];
	}

	const table=document.getElementById(target.getAttribute("dates-list"));
	const list=table.querySelector("tbody");
	list.innerHTML="";

	server_function("getDayDates",{date:day},res=>
	{
		if(res.status)
		{
			if((res.dates) && (res.dates.length))
				fill_list(table,res.dates,item_click);
			//	fill_list(list,table.getAttribute("aria-row"),res.dates,"date_id_",item_click);
		}
	});
}

function item_click(parent,item)
{
	const prev=parent.querySelector(".row_select.selected")
	if(prev)
		prev.classList.remove("selected");
	item.classList.add("selected");
	const form=document.getElementById(item.getAttribute("href"));
	for(const child of item.children)
	{
		const field=form.querySelector("#"+child.getAttribute("aria-field"));
		if(field)
		{
			if(child.hasAttribute("aria-select"))
			{
				//alert(child.querySelector(".option:not(.hide)").getAttribute("value"));
				const selected=child.querySelector(".option:not(.hide)");
				if(selected)
					field.value=selected.getAttribute("value");
				else 
					field.value="";
				continue;
			}
			if(child.firstElementChild)
				field.value=child.firstElementChild.innerHTML;
			else
				field.value=child.innerHTML;
		}
	}
	clear_errors(form,"edit");

	const callback=parent.getAttribute("aria-prefunction");
	if(callback)
		myfuncs[callback](form);

	if(form.classList.contains("popup"))
		form.classList.add("visible");

}

function fill_form(form,data)
{
	document.getElementById(form).querySelectorAll("[aria-field]").forEach(el=>{
		const field=el.getAttribute("id");
		if(data[field])
		{
			if(el.tagName=="IMG")
			{
				if(data["same_origin"])
					el.src=server_base+data[field]
				else
					el.src=data[field];
			}else
				el.value=data[field];
		}else{
			el.value="";
		}
	});
}
function fill_table(parent,data,func=null)
{
	//console.log("fill_table",data);
	fill_list(parent,data,func);
}

function fill_list(parent,data,func=null,field="aria-field")
{
	const noselect=parent.hasAttribute("aria-noclick");
	const row=parent.getAttribute("aria-row");
	const field_id=parent.getAttribute(field);
	const list=parent.querySelector("tbody");
	list.innerHTML="";

	if(!data) return;
	if(!data.length) return;

	const el=document.querySelector("#elements ."+row);
	for(let d in data)
	{
		const item=el.cloneNode(true);
		item.setAttribute("id",field_id+"_"+data[d][field_id]);
		for(const child of item.children)
		{
			if(data[d][child.getAttribute(field)])
			{
				if(child.classList.contains("truncate"))
				{
					child.querySelector(".data").innerHTML=data[d][child.getAttribute(field)];
					continue;
				}
				if(child.hasAttribute("aria-select"))
				{
					child.querySelector(".option[value='"+data[d][child.getAttribute(field)]+"']").
						classList.remove("hide");
					continue;
				}
				child.innerHTML=data[d][child.getAttribute(field)];
			}
		}
		if(!noselect)
		{
			if(func) item.addEventListener("click",e=>{
				e.preventDefault();
				func(parent,item);
			});
		}
		list.append(item);
	}
	if(list.hasAttribute("last_selected"))
	{
		const el=document.getElementById(list.getAttribute("last_selected"));
		if(el)
			el.classList.add("selected");
	}
	list.removeAttribute("last_selected");
}

function add_to_list(parent,data,func=null,field="aria-field")
{
	const noselect=parent.hasAttribute("aria-noclick");
	const row=parent.getAttribute("aria-row");
	const field_id=parent.getAttribute(field);
	const list=parent.querySelector("tbody");
	if(!data) return;

	const el=document.querySelector("#elements ."+row);
	
	const item=el.cloneNode(true);
	item.setAttribute("id",field_id+"_"+data[field_id]);
	for(const child of item.children)
	{
		if(data[child.getAttribute(field)])
		{
			if(child.classList.contains("truncate"))
			{
				child.querySelector(".data").innerHTML=data[child.getAttribute(field)];
				continue;
			}
			if(child.hasAttribute("aria-select"))
			{
				child.querySelector(".option[value='"+data[child.getAttribute(field)]+"']").
					classList.remove("hide");
				continue;
			}
			child.innerHTML=data[child.getAttribute(field)];
		}
	}
	if(!noselect)
	{
		if(func) item.addEventListener("click",e=>{
			e.preventDefault();
			func(parent,item);
		});
	}
	
	list.append(item);

	if(list.hasAttribute("last_selected"))
	{
		const el=document.getElementById(list.getAttribute("last_selected"));
		if(el)
			el.classList.add("selected");
	}
	list.removeAttribute("last_selected");
}


function clear_errors(form,type)
{
	if(type=="add")
	{
		form.classList.add("add");
		form.classList.remove("edit");
	}else{
		form.classList.add("edit");
		form.classList.remove("add");
	}

	const err=document.getElementById(form.getAttribute("aria-error-element"));
	err.innerHTML="";
	err.classList.add("hide");
	form.querySelectorAll("[aria-field]").forEach(el=>
	{
		el.classList.remove("novalid");
	});

	form.querySelectorAll(".operation_confirm").forEach(el=>{
		el.querySelector("input").value="";
		el.classList.add("hide");
	});
}
function empty_fields(form)
{
	form.querySelectorAll("[aria-field]").forEach(el=>
	{
		el.value="";
		el.disabled=false;
	});
}

const myfuncs= {
	pending_checking:function(res)
	{
		if(res.status)
			test_pending(4);
	},
	new_popup_date: function(popup)
	{
		const date=document.getElementById("calendar_date").innerHTML.split("/");
		popup.querySelector("#date_date").value=date[2]+"-"+date[1].padStart(2,'0')+"-"+date[0].padStart(2,'0');
	},
	edit_popup_date: function(popup)
	{

	},
	post_update_calendar: function(data)
	{
		const calendar=document.querySelector(".calendar");
		const list=document.getElementById("dates_list");
		const month=parseInt(calendar.getAttribute("current_month"));
		const year=parseInt(calendar.getAttribute("current_year"));

		if(list.querySelector(".row_select.selected"))
			list.querySelector("tbody").setAttribute("last_selected",
				list.querySelector(".row_select.selected").getAttribute("id"));


		let href=null;
		if(calendar.querySelector("td.selected a"))
			href=calendar.querySelector("td.selected a").getAttribute("href");
		
		server_function("getMonthDates",{month:month+1,year:year},res=>
		{
			update_calendar(calendar,month,year,res.dates);
			if(href)
			{
				if(calendar.querySelector("[href='"+href+"']"))
					calendar.querySelector("[href='"+href+"']").click();		
			}		
		});
	},
	enable_product_data: function(data)
	{
		const disabled=data.querySelector("#prod_pharm").value==0;
		data.querySelectorAll("[aria-field]").forEach(el=>
		{
			el.disabled=disabled;
		});
		data.querySelector("#prod_cost").disabled=false;
		if(disabled) document.getElementById("btn_product_delete").classList.add("hide");
		else document.getElementById("btn_product_delete").classList.remove("hide");
	},

	staff_updated:function(data)
	{
		fill_pharm_options(data.data,"staff","staff_name","staff_id");
	},
	days_updated:function(data)
	{
		update_calendar_days(document.querySelector(".calendar"),data.data.days);
	},
	before_popup_report:function(popup)
	{
		//const prods=popup.querySelector("#report_products");
		//prods.innerHTML="";
		server_function("getSales",{rep_id:popup.querySelector("#rep_id").value},res=>
		{
			if(res.status)
			{
				//console.log("Sales: ",res.data);
				fill_list(document.getElementById("table_sales"),res.data,sale_click,"aria-col");
			}
		});

		if(document.getElementById("filter_service_type").value=="*")
		{
			document.getElementById("btn_report_delete").classList.add("hide");
			document.getElementById("btn_report_save").classList.add("hide");
			document.getElementById("btn_report_remove").classList.remove("hide");
			document.getElementById("btn_report_recover").classList.remove("hide");
			document.getElementById("btn_product_add").classList.add("hide");
		}else{
			document.getElementById("btn_report_delete").classList.remove("hide");
			document.getElementById("btn_report_save").classList.remove("hide");
			document.getElementById("btn_report_remove").classList.add("hide");
			document.getElementById("btn_report_recover").classList.add("hide");
			document.getElementById("btn_product_add").classList.remove("hide");
		}

		// ocultar botones
		popup.querySelectorAll("[aria-to]").forEach(el=>
		{
			const link=document.getElementById(el.getAttribute("aria-to"));
			if((!el.value) || (el.value==""))
				link.classList.add("hide");
			else{
				link.classList.remove("hide");
				if(popup.querySelector("#rep_report_source").value=="local")
					link.href=el.value.replace(/&amp;/g, '&');
				else
					link.href=server_base+el.value.replace(/&amp;/g, '&');
				//link.href=`${server_base}report.php?lng=${page_lng}&type=${link.getAttribute("aria-type")}&service=${popup.querySelector("#rep_id").value}`;
			}
		});
		
	},
	pre_add_sales:function(parent,params)
	{
		const sales=[];
		document.querySelectorAll("#table_sales .sale_row").forEach(sale=>
		{
			const sale_id=sale.querySelector("[aria-col='sale_id']").innerHTML;
			if(sale_id==0)
			{
				sales.push({product_id:sale.querySelector("[aria-col='sale_product_id']").innerHTML,
							product_name:sale.querySelector("[aria-col='sale_product_name']").innerHTML,
							product_cost:sale.querySelector("[aria-col='sale_cost']").innerHTML,
							product_quantity:sale.querySelector("[aria-col='sale_quantity']").innerHTML});
			}else{
				if(sale.classList.contains("hide"))
					sales.push({sale_id:sale_id});
			}
		});
		if(sales.length) params["rep_sales"]=sales;
	},
	post_update_report: function(data)
	{
		const row=document.getElementById("table_reports").querySelector(".report_row.selected");
		row.querySelector("[aria-field='rep_comments']").innerHTML=data.comments;
		if(data.product)
			row.querySelector("[aria-field='rep_product']").innerHTML=data.product+"€";
		else 
			row.querySelector("[aria-field='rep_product']").innerHTML="-";
	},
	post_delete_report: function(data)
	{
		document.getElementById("table_reports").querySelector(".report_row.selected").remove();
	},
	before_popup_patient:function(popup)
	{
		server_function("getPatient",{patient_id:popup.querySelector("#patient_id").value},res=>
		{
			console.log("Patient:",res);
			if(res.status)
			{
				fill_form("form_patient",res.data);
			}else{
				// error
			}
		});		
	},
	new_popup_patient:function(popup)
	{
	},
	post_update_patient:function(data)
	{	
		if(document.querySelector("#table_patients tbody").hasChildNodes())
			document.getElementById("btn_list_patients").click();
	},
	post_delete_patient:function(data)
	{	
		document.querySelector("#table_patients tbody .patient_row.selected").remove();
	},
	post_show_stats_iretia:function(data)
	{
		const stats=data.data;
		document.getElementById("report_stats_iretia_window").classList.remove("hide");
		document.getElementById("btn_print_stats_iretia").classList.remove("hide");
		console.log("Stats:",stats);
		document.getElementById("report_stats_iretia").querySelectorAll("[aria-data]").forEach(el=>{
			const data=el.getAttribute("aria-data");
			if(data!=="")
			{
				if(el.hasAttribute("aria-row"))
				{
					el.innerHTML="";
					const type=el.getAttribute("aria-row");
					const clone_item=document.querySelector("#elements ."+type);
					for(const field of stats[data])
					{
						const item=clone_item.cloneNode(true);
						for(let c=0;c<item.children.length;c++)	
						{
							item.children[c].innerHTML=field[item.children[c].getAttribute("aria-field")];
							if(item.children[c].hasAttribute("aria-post"))
								item.children[c].innerHTML+=item.children[c].getAttribute("aria-post");
						}
						el.append(item);
					}
				}else{
					const parts=data.split("/");
					if(stats[parts[0]])
					{
						if(stats[parts[0]][parts[1]]!=null)
						{
							el.innerHTML=stats[parts[0]][parts[1]];
							if(el.hasAttribute("aria-post"))
								el.innerHTML+=el.getAttribute("aria-post");
						}
					}
				}
			}
		});

	},
	post_show_stats:function(data)
	{
		
		// financial
		const stats=data.data;
		document.getElementById("report_stats_window").classList.remove("hide");
		document.getElementById("btn_print_stats").classList.remove("hide");

		console.log("Stats:",stats);

		const sections=new Array("financial","service");
		for(let i=0;i<sections.length;i++)
		{
			if(stats[sections[i]])
			{
				for(const [type, rows] of Object.entries(stats[sections[i]]))
				{				
					if(rows)
					{
						const tbody=document.querySelector(`[aria-stats="${sections[i]+"_"+type}"]`);
						tbody.innerHTML="";

						const from_row=document.querySelector("#elements ."+tbody.getAttribute("aria-row"));

						if(from_row)
						{
							for(const row of rows)
							{
								const item=from_row.cloneNode(true);
								for(let c=0;c<item.children.length;c++)
								{  
									const key=item.children[c].getAttribute("aria-field");
									if(row[key]!=null)
									{
										if(row[key][0]=="*") 
										{
											item.children[c].innerHTML=page_text[row[key].replaceAll("*","")];
										}else
											item.children[c].innerHTML=row[key];

										if(item.children[c].hasAttribute("aria-post"))
											item.children[c].innerHTML+=item.children[c].getAttribute("aria-post");
									}
								}

								tbody.append(item);
							}
						}
					
					}
				}
			}
		}

		// charts
 		Chart.defaults.font.size = 16;
 		var options={plugins:{
				 legend: {position: 'top',align:'end'},
				 title: {display: true, align:'start',text: 'Servicios',
						 font: {weight:'normal',size: 16}},
				 
			 },
			 scales: {
				 y: {beginAtZero: true}
			 }
 		}

		const charts=data.data.charts;
		const colors=["rgba(54, 162, 235, 1)",
						"#096089",
						"rgba(255, 206, 86, 1",
					];
 		for(let c=0;c<charts.length;c++)
 		{
			const parent=document.getElementById(charts[c].section);
			parent.innerHTML="";
	 		const values=new Array();
	 		values.labels=get_array_text(charts[c].labels);
	 		values.datasets=new Array();
	 		for(let d=0;d<charts[c].datasets.length;d++)
	 		{
		 		const dataset={"label":get_text(charts[c].datasets[d]),
					  "data":charts[c].data[d],
					  "backgroundColor":colors[d]
					 };
		 		values.datasets.push(dataset);
	 		}
	 		options.plugins.title.text=get_text(charts[c].name);
	 
	 		const  chart=document.querySelector("#elements .chart_bars").cloneNode(true);
	 		chart.setAttribute("id",charts[c].section+"_"+(c+1));
	 		parent.append(chart);
	 		const ctx = chart.getContext('2d');
	 		const myChart = new Chart(ctx, {
		 		type: 'bar',
		 		data:values,
		 		options: options
	 		});    
 		}

	}
}

function get_text(text)
{
    if(text[0]=="*") 
        return(page_text[text.substring(1)]);   
    return(text);
}

function get_array_text(arr)
{
    var res=new Array();
    for(let a=0;a<arr.length;a++)
        res.push(get_text(arr[a]));
    return(res);
}

const custom_functions={

	show_agreement:function(el)
	{
		document.getElementById("ag_date").value=format_date(new Date());
		const popup=document.getElementById("agreement_popup");
		popup.setAttribute("aria-form",el.getAttribute("href"));
		popup.querySelector("iframe").setAttribute("src",el.getAttribute("aria-agreement"));
		popup.classList.remove("hide");
		document.getElementById("ag_signer_signature").removeAttribute("src"); 
		document.getElementById("ag_debtor_signature").removeAttribute("src");

		document.getElementById("btn_pending_upload").classList.add("hide");
		document.getElementById("btn_pending_again").classList.add("hide");
	},
	redo_agreement:function(el)
	{
		document.getElementById("btn_pending_upload").classList.add("hide");
		document.getElementById("btn_pending_again").classList.add("hide");
		document.getElementById("btn_pending_service").classList.remove("hide");

		document.getElementById("pending_data").querySelectorAll("[aria-field]").forEach(el=>
		{
			el.disabled=false;
		});
	},
	update_video:function(el)
	{
		const prev=document.getElementById(el.getAttribute("href")).querySelector(".videos_list .selected");
		if(prev)
		{
			//document.getElementById("formation_video").classList.remove("hide");
			//document.getElementById("formation_video").setAttribute("src",prev.getAttribute("href"));	
		}else{
			//document.getElementById("formation_video").classList.add("hide");
		}
	}
};

function format_date(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    
    return `${year}-${month}-${day}`;
}

function validate_password(e)
{

  const submitButton = document.getElementById('btn_update_password');

  const req1= document.getElementById('password_req_1');
  const req2= document.getElementById('password_req_2');
  const req3= document.getElementById('password_req_3');
  const req4= document.getElementById('password_req_4');

  const password=document.getElementById('new_password').value;
  const repassword=document.getElementById('renew_password').value;

  // Validar número
  if (/\d/.test(password)) {
	req1.classList.add('valid');
	//reqNumber.classList.remove('invalid');
  } else {
	//reqNumber.classList.add('invalid');
	req1.classList.remove('valid');
  }

  // Validar mayúscula
  if (/[A-Z]/.test(password)) {
	req2.classList.add('valid');
	//reqUppercase.classList.remove('invalid');
  } else {
	//reqUppercase.classList.add('invalid');
	req2.classList.remove('valid');
  }

  // Validar símbolo
  if (/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
	req3.classList.add('valid');
	//reqSymbol.classList.remove('invalid');
  } else {
	//reqSymbol.classList.add('invalid');
	req3.classList.remove('valid');
  }

  // Validar coincidencia
  if (password === repassword && password !== '') {
	req4.classList.add('valid');
	//reqMatch.classList.remove('invalid');
  } else {
	//reqMatch.classList.add('invalid');
	req4.classList.remove('valid');
  }

  // Habilitar/deshabilitar botón
  const allValid = document.querySelectorAll('.password_reqs li.valid').length === 4;
  if (allValid) {
	submitButton.disabled = false;
	submitButton.classList.remove('disabled');
  } else {
	submitButton.disabled = true;
	submitButton.classList.add('disabled');
  }

}

function close_alert_popup()
{
	document.getElementById("popup_msg").classList.remove("visible");
}
async function alert_popup(title,msg,err,is_input=false,password=false,extra_data=null)
{
	const popup=document.getElementById("popup_msg");
	document.body.append(popup);
	popup.querySelector(".modal_title").innerHTML=title;
	popup.querySelector(".modal_msg").innerHTML=msg;

	const input=popup.querySelector(".modal_prompt");
	
	if(err) popup.classList.add("error");
	else popup.classList.remove("error");

	if(is_input)
	{
		popup.classList.add("input");
		if(password)
			input.setAttribute("type","password");
		else
			input.setAttribute("type","text");
	}else
		popup.classList.remove("input");

	const extra=popup.querySelector(".modal_extra");
	if(extra_data)
	{
		const link=extra.querySelector(".modal_extra_link");
		const new_link=link.cloneNode(true)
		link.replaceWith(new_link);
		new_link.innerHTML=extra_data.link;
		new_link.addEventListener("click",e=>{
			e.preventDefault();
			extra_data.callback();
		});
		extra.querySelector(".modal_extra_info").innerHTML=extra_data.info;
		extra.classList.remove("hide");
	}else{
		extra.classList.add("hide");
	}

	const btn_confirm=popup.querySelector(".modal_btn_ok");
	const btn_cancel=popup.querySelector(".modal_btn_cancel");
	btn_confirm.replaceWith(btn_confirm.cloneNode(true));
	btn_cancel.replaceWith(btn_cancel.cloneNode(true));
	popup.classList.add("visible");
	if(is_input)
		input.focus();

	return new Promise((resolve) => {
        popup.querySelector(".modal_btn_ok").onclick = (e) => {
			e.preventDefault();
        	popup.classList.remove("visible");
			if(is_input)
			{
				const val=popup.querySelector(".modal_prompt").value;
				popup.querySelector(".modal_prompt").value="";
				resolve(val);
			}
	        resolve(true);
        };
		popup.querySelector(".modal_btn_cancel").onclick = (e) => {
			e.preventDefault();
			popup.classList.remove("visible");
			popup.querySelector(".modal_prompt").value="";
			resolve(false);
		  };
      });
	
}
document.addEventListener("DOMContentLoaded", load, false);